.container {
  width: 320px;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
}

.overlayMobile {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 0;
  height: 100%;
}

.container:hover .overlay {
  width: 100%;
  left: 0;
}

.imgText {
  color: white;
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 47%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: tanslate(-50%, -50%);
  white-space: wrap;
  text-align: center;
}

.imgText:hover {
  color: #7480cb;
}
