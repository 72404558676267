body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  font-family: "Lato";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  font-family: "Lato", "Roboto";
}

h1 {
  color: #244ba6;
  text-transform: uppercase;
  font-size: 27px;
  text-align: left;
  font-family: "Lato";
  font-weight: 900;
}

p {
  font-size: 17px;
  text-align: left;
  font-family: "Lato";
  font-weight: 600;
}
.responsiveHero img {
  width: "120%";
}

.image-wrap {
  width: 100%;
  height: 50vw;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .image-wrap img {
  width: 100%;
  animation: move 5s ease;
  -ms-animation: move 5s ease;
  -webkit-animation: move 5s ease;
  -moz-animation: move 5s ease;
  position: absolute;
  animation-fill-mode: forwards;
} */

@keyframes move {
  0% {
    /* -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left; */
    transform: scale(1.35);
    -ms-transform: scale(1.35);
    /* IE 9 */

    -webkit-transform: scale(1.35);
    /* Safari and Chrome */

    -o-transform: scale(1.35);
    /* Opera */

    -moz-transform: scale(1.35);
    /* Firefox */
  }
  100% {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    /* IE 9 */

    -webkit-transform: scale(1.1);
    /* Safari and Chrome */

    -o-transform: scale(1.1);
    /* Opera */

    -moz-transform: scale(1.1);
    /* Firefox */
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.lineUpCarousel {
  /* animation: 5s anim-lineUp ease-out forwards infinite; */
  position: absolute;
  bottom: 17%;
  left: 7%;
  text-transform: uppercase;
}

.slide-up {
  top: 140px;
  left: 10px;
  animation: slideUp ease 0.5s forwards 1.2s infinite;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 2px black;
  font-weight: 600;
}

.lineUp {
  animation: 5s anim-lineUp ease infinite;
  position: absolute;
  bottom: 15%;
  left: 10%;
  text-transform: uppercase;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.control-dots {
  display: flex;
  justify-content: center;
}

.mobileLists li {
  text-indent: -23px;
  margin-top: 3px;
}

.shadow {
  filter: drop-shadow(1px 1px 2px black);
}
.noShadow {
  filter: drop-shadow(0px 0px 0px #244ba6);
}

@media (max-width: 900px) {
  .image-container {
    padding-top: 58px;
  }
}
