
#align_center {
  text-align: center;
}


.divider {
  display:flex;
  align-items: center;
}
.divider::after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: #244ba6;
  margin: 0 8px;
}
